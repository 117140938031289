import SimpleTextComponent from "../../shared/ui/SimpleTextComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import PurpleTickIcon from "../../../core/assets/module/shared/tick-purple.svg";
import SimpleRetirementComponent from "../../shared/ui/SimpleRetirementComponent";

const ProductItemDescriptionComponent = ({ open, onDismiss, description }: { open: boolean; onDismiss: any; description: string }) => {
    const elements = description === 'Basic'
        ? [
            "Фон сайта (Цвет, изображение)",
            "Профиль сайта",
            "Текстовый блок",
            "Неограниченные ссылки",
            "Аналитика",
            "SEO, QR-код",
            "Готовые шаблоны",
            "Изображение, видео и музыка",
            "Форма для заявки",
            "Таймер",
            "Карта (Яндекс)"
        ]
        : description === 'Pro'
            ? [
                "Фон сайта (Цвет, изображение)",
                "Профиль сайта",
                "Текстовый блок",
                "Неограниченные ссылки",
                "Аналитика",
                "SEO, QR-код",
                "Готовые шаблоны",
                "Изображение, видео и музыка",
                "Форма для заявки",
                "Таймер",
                "Карта (Яндекс)"
            ]
            : [
                "Всё, что на тарифе Pro",
                "Доступно 3 веб-сайта (скидка 20%)"
            ];

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title={`Тариф “${description === ('Basic' || 'Pro') ? 'Pro' : 'Premium'}”`}>
            {
                elements.map((field, index) =>
                    <div key={index} style={{ display: 'flex', alignItems: 'center', paddingBottom: elements.length - 1 !== index ? 8 : 0 }}>
                        <img src={PurpleTickIcon} alt="tick icon" />
                        <SimpleRetirementComponent size={16} />
                        <SimpleTextComponent type='hardMedium'>{field}</SimpleTextComponent>
                    </div>
                )
            }
        </StandartBottomSheetComponent>
    );
}

export default ProductItemDescriptionComponent;